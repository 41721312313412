/**
 * 店铺审核状态
 * （0：待审核，1：审核通过，2：审核驳回)
 * */
export enum EShopAduitStatus {
  /** 没有店铺 */
  None = -1,
  /** 待审核  */
  Pending,
  /** 审核通过 */
  Success,
  /** 审核驳回 */
  Failed
}

/**
 * 店铺状态
 */
export enum EShopStoreStatus {
  /** 未开业 */
  ComingSoon,
  /** 营业 */
  Open,
  /** 歇业 */
  Suspension,
  /** 关店 */
  Close,
  /** 已过期 */
  Expire
}

/**
 * 店铺状封禁状态
 */
export enum EShopForbiddenStatus {
  /** 开启 */
  Enabled = 1,
  /** 禁用 */
  Disabled
}

/**
 * 店铺开户类型
 */
export enum EShopPayUserType {
  /** 企业用户 */
  Enterprise = '12',
  /** 个体工商用户 */
  Person = '13'
}

export const EShopPayUserTypeName = {
  [EShopPayUserType.Enterprise]: '企业用户',
  [EShopPayUserType.Person]: '个体户'
}

/**
 * 开户店铺用户证件类型
 */
export enum EShopPayUserCredentialType {
  /** 身份证 */
  Id = '0',
  /** 护照 */
  Passport = '2',
  /** 港澳通行证 */
  Hongkong = '5',
  /** 台湾通行证 */
  Taiwan = '6'
}

export const EShopPayUserCredentialTypeName = {
  [EShopPayUserCredentialType.Id]: '身份证',
  [EShopPayUserCredentialType.Passport]: '护照',
  [EShopPayUserCredentialType.Hongkong]: '港澳通行证',
  [EShopPayUserCredentialType.Taiwan]: '台湾通行证'
}
