import { EAuthCode } from '~/enums/EAuthCode'
import { TRoutes } from '~/routes/const'
import { routeNames } from '../const'

const routes: TRoutes[] = [
  {
    path: routeNames.mainGraphWatermark,
    component: () => import('../../pages/marketingManagement/mainGraphWatermark'),
    meta: {
      title: '主图水印',
      code: EAuthCode.MAINGRAPHWATERMARK_TEMPLATE
    }
  },
  {
    path: routeNames.mainGraphWatermarkAdd,
    component: () => import('../../pages/marketingManagement/mainGraphWatermark/add'),
    meta: {
      title: '详情',
      hideInMenu: true,
      code: [EAuthCode.MAINGRAPHWATERMARK_TEMPLATE_ADD, EAuthCode.MAINGRAPHWATERMARK_TEMPLATE_UPDATE, EAuthCode.MAINGRAPHWATERMARK_TEMPLATE_DETAIL]
    }
  },
  {
    path: routeNames.groupList,
    component: () => import('../../pages/marketingManagement/groupList'),
    meta: {
      title: '拼团活动列表',
      code: [EAuthCode.GROUPLIST_LIST]
    }
  },
  {
    path: routeNames.group,
    component: () => import('../../pages/marketingManagement/group'),
    meta: {
      title: '拼团管理',
      code: [EAuthCode.GROUP_TEMPLATE]
    }
  },
  {
    path: routeNames.groupListAdd,
    component: () => import('../../pages/marketingManagement/groupList/add'),
    meta: {
      hideInMenu: true,
      title: '拼团详情',
      code: [EAuthCode.GROUPLIST_LIST_UPDATE, EAuthCode.GROUPLIST_LIST_ADD]
    }
  },
  {
    path: routeNames.groupListCheck,
    component: () => import('../../pages/marketingManagement/groupList/check'),
    meta: {
      hideInMenu: true,
      title: '拼团详情',
      code: [EAuthCode.GROUPLIST_LIST_READONLY]
    }
  },
  {
    path: routeNames.presellList,
    component: () => import('../../pages/marketingManagement/presellList'),
    meta: {
      title: '预售活动列表',
      code: [EAuthCode.PRESELLLIST_LIST]
    }
  },
  {
    path: routeNames.presellListAdd,
    component: () => import('../../pages/marketingManagement/presellList/add'),
    meta: {
      hideInMenu: true,
      title: '预售详情',
      code: [EAuthCode.PRESELLLIST_LIST_UPDATE, EAuthCode.PRESELLLIST_LIST_ADD]
    }
  },
  {
    path: routeNames.presellListCheck,
    component: () => import('../../pages/marketingManagement/presellList/check'),
    meta: {
      hideInMenu: true,
      title: '预售详情',
      code: [EAuthCode.PRESELLLIST_LIST_READONLY]
    }
  },

  {
    path: routeNames.presell,
    component: () => import('../../pages/marketingManagement/presell'),
    meta: {
      title: '预售管理',
      code: [EAuthCode.PRESELL_TEMPLATE]
    }
  },
  {
    path: routeNames.mainGraphWatermarkDetailList,
    component: () => import('../../pages/marketingManagement/mainGraphWatermark/detailList'),
    meta: {
      title: '管理商品',
      hideInMenu: true
    }
  },
  {
    path: routeNames.mainGraphWatermarkCheck,
    component: () => import('../../pages/marketingManagement/mainGraphWatermark/check'),
    meta: {
      title: '管理商品',
      hideInMenu: true
    }
  },
  {
    path: routeNames.marketingLotteryTurntable,
    component: () => import('../../pages/marketingManagement/lotteryTurntable'),
    meta: {
      title: '大转盘',
      code: EAuthCode.LOTTERY_TURNTABLE
    }
  },
  {
    path: routeNames.marketingLotteryTurntableDetail,
    component: () => import('../../pages/marketingManagement/lotteryTurntableDetail'),
    meta: {
      title: '编辑大转盘',
      hideInMenu: true,
      code: [EAuthCode.LOTTERY_TURNTABLE_UPDATE, EAuthCode.LOTTERY_TURNTABLE_ADD]
    }
  },
  {
    path: routeNames.marketingLotteryTurntableMember,
    component: () => import('../../pages/marketingManagement/lotteryTurntableMember'),
    meta: {
      title: '参与用户',
      hideInMenu: true,
      code: [EAuthCode.LOTTERY_TURNTABLE_USERLIST]
    }
  },
  {
    path: routeNames.marketingLotteryTurntableInfo,
    component: () => import('../../pages/marketingManagement/lotteryTurntableInfo'),
    meta: {
      title: '大转盘详情',
      hideInMenu: true,
      code: EAuthCode.LOTTERY_TURNTABLE_DETAIL
    }
  },
  {
    path: routeNames.marketingPlanetActivity,
    component: () => import('../../pages/marketingManagement/planetActivity/list'),
    meta: {
      title: '平台活动',
      code: EAuthCode.MARKETING_PLANETACTIVITY
    }
  },
  {
    path: routeNames.marketingPlanetActivityDiscountSaleApply,
    component: () => import('../../pages/marketingManagement/planetActivity/discountSaleApply'),
    meta: {
      title: '满减满折报名',
      hideInMenu: true,
      code: [EAuthCode.MARKETING_PLANETACTIVITY_APPLY, EAuthCode.MARKETING_PLANETACTIVITY_APPLYAGAIN]
    }
  },
  {
    path: routeNames.marketingPlanetActivityTimeBuyApply,
    component: () => import('../../pages/marketingManagement/planetActivity/timeBuyApply'),
    meta: {
      title: '限时抢购报名',
      hideInMenu: true,
      code: [EAuthCode.MARKETING_PLANETACTIVITY_APPLY, EAuthCode.MARKETING_PLANETACTIVITY_APPLYAGAIN]
    }
  },
  {
    path: routeNames.marketingPlanetActivityDiscountSaleHistory,
    component: () => import('../../pages/marketingManagement/planetActivity/discountSaleHistory'),
    meta: {
      title: '满减满折历史活动',
      hideInMenu: true,
      code: EAuthCode.MARKETING_PLANETACTIVITY_HISTORY
    }
  },
  {
    path: routeNames.marketingPlanetActivityTimeBuyHistory,
    component: () => import('../../pages/marketingManagement/planetActivity/timeBuyHistory'),
    meta: {
      title: '限时抢购历史活动',
      hideInMenu: true,
      code: EAuthCode.MARKETING_PLANETACTIVITY_HISTORY
    }
  },
  {
    path: routeNames.marketingTimeBuyList,
    component: () => import('../../pages/marketingManagement/timeBuyList'),
    meta: {
      title: '限时抢购列表',
      code: EAuthCode.MARKETING_TIMEBUYSTORE
    }
  },
  {
    path: routeNames.marketingTimeBuyRecord,
    component: () => import('../../pages/marketingManagement/timeBuyRecord'),
    meta: {
      title: '限时抢购管理',
      code: EAuthCode.MARKETING_TIMEBUYSTORERECORD
    }
  },
  {
    path: routeNames.marketingTimeBuyDetail,
    component: () => import('../../pages/marketingManagement/timeBuyDetail'),
    meta: {
      title: '限时抢购详情',
      hideInMenu: true,
      code: [EAuthCode.MARKETING_TIMEBUYSTORE_ADD, EAuthCode.MARKETING_TIMEBUYSTORE_UPDATE]
    }
  },
  {
    path: routeNames.marketingTimeBuyInfo,
    component: () => import('../../pages/marketingManagement/timeBuyInfo'),
    meta: {
      title: '查看限时抢购',
      hideInMenu: true,
      code: EAuthCode.MARKETING_TIMEBUYSTORE_DETAIL
    }
  }
]

export default routes
