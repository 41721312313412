import { routeNames, TRoutes } from '~/routes/const'
import { EAuthCode } from '~/enums/EAuthCode'

const routes: TRoutes[] = [
  {
    path: routeNames.shopSettingOrderSetting,
    component: () => import('../../pages/shopSetting/orderSetting'),
    meta: {
      title: '订单设置',
      code: EAuthCode.SHOPSETTING_ORDERSETTING
    }
  },
  {
    path: routeNames.shippingSetting,
    component: () => import('../../pages/shopSetting/shippingSetting'),
    meta: {
      title: '配送设置',
      code: EAuthCode.SHOPSETTING_SHIPPINGSETTING
    }
  },
  {
    path: routeNames.freightTemplate,
    component: () => import('../../pages/shopSetting/freightTemplate'),
    meta: {
      title: '运费模板',
      hideInMenu: true,
      code: [EAuthCode.SHOPSETTING_SHIPPINGSETTING_SHIPPINGTEMPLATEADD, EAuthCode.SHOPSETTING_SHIPPINGSETTING_SHIPPINGTEMPLATEUPDATE]
    }
  },
  {
    path: routeNames.expressWaybill,
    component: () => import('../../pages/shopSetting/expressWaybill'),
    meta: {
      title: '物流运单',
      hideInMenu: true,
      code: [EAuthCode.SHOPSETTING_SHIPPINGSETTING_EXPRESSDETAIL, EAuthCode.SHOPSETTING_SHIPPINGSETTING_DISTRIBUTIONUPDATE]
    }
  },
  {
    path: routeNames.shopSettingClientSecret,
    component: () => import('../../pages/shopSetting/clientSecret'),
    meta: {
      title: '应用管理',
      code: EAuthCode.SETTING_CLIENT
    }
  }
]

export default routes
