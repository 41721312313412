import { EAuthCode } from '~/enums/EAuthCode'
import { TRoutes } from '~/routes/const'
import { routeNames } from '../const'

export default [
  {
    path: routeNames.shopState,
    component: () => import('../../pages/businessManagement/shopState'),
    meta: { title: '店铺状态', code: EAuthCode.BUSINESS_SHOPSTATE }
  },
  {
    path: routeNames.shopStateShadow,
    component: () => import('../../pages/businessManagement/state/shadow'),
    meta: { title: '提交影印件资料', hideInMenu: true }
  },
  {
    path: routeNames.shopStateTiedCards, // 企业账户绑卡
    component: () => import('../../pages/businessManagement/state/tiedCards'),
    meta: { title: '主账户绑卡并签约', hideInMenu: true }
  },
  {
    path: routeNames.shopStateTiedPerson, // 个体户绑卡
    component: () => import('../../pages/businessManagement/state/tiedCards/person'),
    meta: { title: '主账户打款认证开户', hideInMenu: true }
  },
  {
    path: routeNames.shopStateBasic,
    component: () => import('../../pages/businessManagement/state/basic'),
    meta: { title: '提交开户资质', hideInMenu: true }
  },
  {
    path: routeNames.shopStateOpen,
    component: () => import('../../pages/businessManagement/state/open'),
    meta: { title: '主账户收款签约', hideInMenu: true }
  },
  {
    path: routeNames.shopStateWeixin,
    component: () => import('../../pages/businessManagement/state/weixin'),
    meta: { title: '微信进件资料提交', hideInMenu: true }
  },
  {
    path: routeNames.shopStateWeChat,
    component: () => import('../../pages/businessManagement/state/weChat'),
    meta: { title: '微信实名认证', hideInMenu: true }
  },
  {
    path: routeNames.shopStateEarnest,
    component: () => import('../../pages/businessManagement/state/earnest'),
    meta: { title: '账户签约', hideInMenu: true }
  },
  {
    path: routeNames.shopStateDetail,
    component: () => import('../../pages/businessManagement/shopStateDetail'),
    meta: { title: '店铺充值', hideInMenu: true, code: [EAuthCode.BUSINESS_SHOPSTATE_WITHDRAW, EAuthCode.BUSINESS_SHOPSTATE_RECHARGE] }
  },
  {
    path: routeNames.shopMessage,
    component: () => import('../../pages/businessManagement/shopMessage'),
    meta: { title: '店铺信息', code: [EAuthCode.BUSINESS_SHOPMESSAGE] }
  },
  {
    path: routeNames.enterpriseInformation,
    component: () => import('../../pages/businessManagement/enterpriseInformation'),
    meta: { title: '企业信息', code: EAuthCode.BUSINESS_ENTERPRISEINFORMATION }
  },
  {
    path: routeNames.businessCategory,
    component: () => import('../../pages/businessManagement/businessCategory'),
    meta: { title: '经营类目', code: EAuthCode.BUSINESS_CLASSIFY }
  },
  {
    path: routeNames.brandManagement,
    component: () => import('../../pages/businessManagement/brandManagement'),
    meta: { title: '品牌管理', code: [EAuthCode.BUSINESS_BRANDMANAGEMENT] }
  },
  {
    path: routeNames.brandManagementDetail,
    component: () => import('../../pages/businessManagement/brandManagementDetail'),
    meta: {
      title: '品牌详情',
      hideInMenu: true,
      code: [EAuthCode.BUSINESS_BRANDMANAGEMENT_ADD, EAuthCode.BUSINESS_BRANDMANAGEMENT_UPDATE, EAuthCode.BUSINESS_BRANDMANAGEMENT_BRANDCLAIM]
    }
  },
  {
    path: routeNames.employeeManagement,
    component: () => import('../../pages/businessManagement/employeeManagement'),
    meta: { title: '员工管理', code: EAuthCode.BUSINESS_STAFF }
  },
  {
    path: routeNames.businessDepartment,
    component: () => import('../../pages/businessManagement/departmentManagement'),
    meta: { title: '商家部门管理', code: EAuthCode.BUSINESS_DEPT }
  },
  {
    path: routeNames.roleManagement,
    component: () => import('../../pages/businessManagement/roleManagement'),
    meta: { title: '角色管理', code: EAuthCode.BUSINESS_ROLE }
  },
  {
    path: routeNames.logRecord,
    component: () => import('../../pages/businessManagement/logRecord'),
    meta: {
      title: '操作日志记录',
      code: [EAuthCode.BUSINESS_LOGRECORD]
    }
  }
] as TRoutes[]
