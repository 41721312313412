import { TRoutes } from '~/routes/const'
import { routeNames } from '../const'
import { EAuthCode } from '~/enums/EAuthCode'

const routes: TRoutes[] = [
  {
    path: routeNames.dataOverview,
    component: () => import('~/pages/dataAnalysis/dataBasics'),
    meta: { title: '数据概况', code: EAuthCode.DATAANALYSIS_DATABASICS }
  }
]

export default routes
