import React from 'react'
import GlobalContext, { IGlobalContext } from './global.context'
import { getMenus } from '../routes'
import { api } from '~/request'
import { filterMenuByCode } from '~/routes/const'
import { EShopAduitStatus, EShopForbiddenStatus, EShopStoreStatus } from '~/enums/EShopAduitStatus'
import { skipAuth, systemConfig } from '~/config'
import { JsonResultSysAuthVo } from '~/request/data-contracts'

/**
 * 全局context的Provider。
 *
 * @export
 * @class GlobalProvider
 * @extends {React.Component<any, IGlobalContext>}
 */
export default class GlobalProvider extends React.Component<any, IGlobalContext> {
  static contextType = GlobalContext

  dispatch: (key: any, value?: any) => void

  constructor(props: any) {
    super(props)

    this.dispatch = (key: any, value?: any) => {
      if (typeof key === 'string') {
        const state: any = { [key]: value }
        this.setState(state)
      } else {
        this.setState(key)
      }
    }

    this.state = {
      layoutSetting: { ...systemConfig.layoutSetting },
      breadcrumb: { ...systemConfig.breadcrumb },
      breadcrumbs: [],
      name: '',
      storeInfo: {
        storeNo: '',
        checkStatus: EShopAduitStatus.None,
        rejectReason: '',
        storeStatus: EShopStoreStatus.ComingSoon,
        forbid: EShopForbiddenStatus.Disabled
      },
      applicationName: '道索内容管理系统',
      menus: [],
      authCodes: [],
      dispatch: this.dispatch,
      dispatchAction: async (key: string, payload?: any) => {
        return this[key] && this[key].apply(this, [payload])
      }
    }
  }

  render() {
    return <GlobalContext.Provider value={this.state}>{this.props.children}</GlobalContext.Provider>
  }

  getUserInfo = (skipInterceptor) => {
    return api['/admin/auth/query_GET']({}, { skipInterceptor })
      .then((data) => (skipInterceptor ? data.data : data) as JsonResultSysAuthVo)
      .then(({ data }) => {
        if (data) {
          const { buttonCodes = [], menusCodes = [], username = '', storeInfo = {} } = data
          const authCodes = buttonCodes.concat(menusCodes)

          const menus = storeInfo.checkStatus === EShopAduitStatus.Success ? filterMenuByCode(getMenus(), menusCodes, skipAuth) : []

          this.setState({
            name: username,
            storeInfo: {
              storeNo: storeInfo.storeNo || '',
              checkStatus: storeInfo.checkStatus !== undefined ? storeInfo.checkStatus : EShopAduitStatus.None,
              rejectReason: storeInfo.rejectReason || '',
              storeStatus: storeInfo.storeStatus !== undefined ? storeInfo.storeStatus : EShopStoreStatus.ComingSoon,
              forbid: storeInfo.forbid !== undefined ? storeInfo.forbid : EShopForbiddenStatus.Disabled
            },
            authCodes,
            menus
          })
        }
        return data
      })
  }
}
